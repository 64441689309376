import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const baseQuery = fetchBaseQuery({
	baseUrl: process.env.REACT_APP_API_URL,
	prepareHeaders: (headers, { getState }) => {
		// const token = (getState() as RootState).auth.access_token;

		// if (token) {
		//   headers.set("authorization", `Bearer ${token}`);
		// }
		return headers;
	},
});

export const api = createApi({
	reducerPath: "api",
	tagTypes: ["Drivers", "Services", "Units"],
	baseQuery: baseQuery,
	endpoints: () => ({}),
});
