import { DriverDto } from "../../../app/services/dto/driver.dto";
import { useDrivers } from "../../../app/services/hooks/driverHooks";
import Table, { TableProps } from "../../Table";
import { customTextAreaFilter } from "../../Table/customFilters";

export const DriverDetails = () => {
  const props = driverTableProps();

  return <Table {...props} />;
};

export const driverTableProps = (): TableProps<DriverDto> => {
  const { refetch, table, isLoading } = useDrivers();

  return {
    data: table as [],
    columns: [
      {
        id: "id",
        title: "WIALON_IDENTIFIER",
        field: "wialon_identifier",
        customFilterAndSearch: customTextAreaFilter,
        hidden: true,
      },
      { id: "id", title: "DEPARTMENT", field: "DEPARTMENT" },
      { id: "id", title: "EMPLOYEE_ID", field: "EMPLOYEE_ID" },
      { id: "id", title: "DRIVER_NAME", field: "DRIVER_NAME" },
      { id: "id", title: "MOBILE_NUMBER", field: "MOBILE_NUMBER" },
      { id: "id", title: "EID_CATEGORY", field: "EID_CATEGORY" },
      { id: "id", title: "EID_NUMBER", field: "EID_NUMBER", hidden: true },
      { id: "id", title: "EID_EXPIRY", field: "EID_EXPIRY" },
      { id: "id", title: "LICENSE_CATEGORY", field: "LICENSE_CATEGORY" },
      {
        id: "id",
        title: "LICENSE_NUMBER",
        field: "LICENSE_NUMBER",
        hidden: true,
      },
      { id: "id", title: "LICENSE_EXPIRY", field: "LICENSE_EXPIRY" },
      { id: "id", title: "LICENSE_LINK", field: "LICENSE_LINK" },
      { id: "id", title: "EID_LINK", field: "EID_LINK" },
    ],
    actions: [
      {
        icon: "refresh",
        tooltip: "Refresh",
        isFreeAction: true,
        onClick: refetch,
      },
    ],
    title: "Drivers",
    isLoading,
  };
};
