import { Collapse, List, ListItemButton } from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { LinkedItem, LinkedItemProps } from ".";

export const NestedLinkedItem: FC<LinkedItemProps> = ({
  title,
  route,
  icon,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText
          primary={t(title)}
          primaryTypographyProps={{
            fontSize: 20,
            fontWeight: "medium",
            letterSpacing: 0,
          }}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open}>
        <List sx={{ pl: 3 }}>
          {route &&
            route.map((item, index) => {
              if (item.route) {
                return <NestedLinkedItem {...item} key={index} />;
              } else if (item.isAllowed) {
                return <LinkedItem {...item} key={index} />;
              }
              return null;
            })}
        </List>
      </Collapse>
    </>
  );
};
