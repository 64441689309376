import { Icon } from "@material-ui/core";
import { ServiceDto } from "../../../app/services/dto/service.dto";
import { useServices } from "../../../app/services/hooks/serviceHooks";
import Table, { TableProps } from "../../Table";

export const ServiceDetails = () => {
  const props = serviceTableProps();

  return <Table {...props} />;
};

export const serviceTableProps = (): TableProps<ServiceDto> => {
  const { refetch, table, isLoading } = useServices();

  return {
    data: table as [],
    columns: [
      {
        id: "id",
        title: "Date in",
        field: "date",
        type: "date",
      },
      {
        id: "id",
        title: "Date out",
        field: "date_out",
        type: "date",
      },
      {
        id: "id",
        title: "Plate No",
        field: "vehicle.plate_no",
      },
      { id: "id", title: "Model", field: "vehicle.model", defaultFilter: "" },
      { id: "id", title: "Type", field: "type" },
      { id: "id", title: "Service Interval", field: "interval" },
      { id: "id", title: "Service Mileage", field: "service_mileage" },
      { id: "id", title: "Replacement Vehicle", field: "replacment_vehicle.plate_no" },
    ],
    actions: [
      {
        icon: "refresh",
        tooltip: "Refresh",
        isFreeAction: true,
        onClick: refetch,
      },
    ],
    title: "Vehicle Services",
    isLoading,
    optionProps: {
      grouping: true,
      selection: true,
      selectionProps: {},
    },
    detailPanel: [
      {
        icon: "account_circle",
        tooltip: "Show Surname",
        render: (rowData) => {
          return (
            <Icon
              style={{
                fontSize: 100,
                textAlign: "center",
                color: "white",
                backgroundColor: "#E53935",
              }}
            >
              {((rowData as unknown) as ServiceDto)?.image}
            </Icon>
          );
        },
      },
    ],
  };
};
