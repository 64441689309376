import { Card, Grid, Typography } from "@material-ui/core";
import ServiceOutForm from "components/Form/ServiceOut";
import { useTranslation } from "react-i18next";

export const ServiceOut = () => {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant='h2' sx={{ fontWeight: 700 }}>
        {t("ADM Service Registration")}
      </Typography>
      <Grid component={Card} sx={{ padding: 6 }} maxWidth='sm'>
        <ServiceOutForm />
      </Grid>
    </>
  );
};
