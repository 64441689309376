import { createContext, useContext } from 'react';

export interface FormattedReportTable {
    columns: string[];
    data: Record<string, unknown>[];
}

interface PageContextProps {
    loading: boolean,
    setLoading: (loading: boolean) => void,
    tableData: FormattedReportTable | null
    setTable: (loading: FormattedReportTable) => void,
    tableTitle: string
    setTableTitle: (title: string) => void
}

export const PageContext = createContext<PageContextProps>({
    setTable: (report: FormattedReportTable) => console.log(report),
    setLoading: (loading: boolean) => console.log(loading),
    setTableTitle: (title: string) => console.log(title),
    loading: false,
    tableData: null,
    tableTitle: ""
});
export const usePage = () => useContext(PageContext);