import { Skeleton, Stack } from "@material-ui/core";

const UpcomingReport = () => {
    return <Stack>
        <Skeleton animation="wave" height={30} />
        <Skeleton variant="rectangular" height={100} />
        <Skeleton animation="wave" height={30} />
    </Stack>
}

export default UpcomingReport