import { ThemeProvider } from "@emotion/react";
import { Box, createTheme, CssBaseline } from "@material-ui/core";
import { blueGrey, indigo, lightGreen } from "@material-ui/core/colors";
import { WialonApi } from "api";
import Display from "components/Display";
import Page404 from "components/Display/Page404";
import { WialonContext } from "context/useWialon";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";

export const theme = createTheme({
  typography: {
    fontSize: 12,
  },
  palette: {
    primary: {
      main: indigo[500],
    },
    secondary: {
      main: lightGreen[600],
      contrastText: "#fff",
    },
    grey: blueGrey,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          overflow: "overlay",
          "*::-webkit-scrollbar": {
            width: "0.8em",
          },
          "*::-webkit-scrollbar-track": {
            display: "none",
          },
          "*::-webkit-scrollbar-thumb": {
            backgroundColor: "#babac0",
            borderRadius: 16,
          },
        },
      },
    },
  },
});

const App = () => {
  const search = useLocation().search;
  const { i18n } = useTranslation();
  const sid = new URLSearchParams(search).get("sid") || "";
  const user = new URLSearchParams(search).get("user") || "";
  const language = new URLSearchParams(search).get("lang") || "en";
  const [wialon, setWialon] = useState<WialonApi | null>(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
      setWialon(WialonApi.init(sid));
      i18n.changeLanguage(language);
    }
  }, [sid, theme]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <WialonContext.Provider value={{ wialon: wialon }}>
          <CssBaseline />
          <ToastContainer />
          <Box
            sx={{
              display: "flex",
              height: "100vh",
            }}
          >
            <Router>
              <Switch>
                {sid && (user === "atsuae" || user === "ats.adm.bayoumi" || user === "ats.adm.osama" || user === "ats.jasem.alsalama" || user === "ats.adm.yousef" || user === "ats.adm.diaa-alanizan" || user === "ats.adm.hassan" || user === "ats.adm.nitin" || user === 'ats.adm.abdulla.hassan') ? (
                  <Route component={Display} />
                ) : (
                  <Route component={Page404} />
                )}
              </Switch>
            </Router>
          </Box>
        </WialonContext.Provider>
      </ThemeProvider>
    </>
  );
};

export default App;
