import { Route } from "react-router";
import { WorkshopDetails } from "./Details";

const DisplayWorkshop = ({ user }: { user: string | null }) => {
  return (
    <>
      {user && ["atsuae"].includes(user) && (
        <Route
          component={WorkshopDetails}
          path='/workshop-almasoud'
          strict={true}
        />
      )}
    </>
  );
};

export default DisplayWorkshop;
