import { useMemo } from "react";
import { entitySelector } from "utility/entitySelectors";
import { useGetDriversQuery } from "../driverServices";
import { DriverDto } from "../dto/driver.dto";

export const useDrivers = () => {
  const { data, error, isFetching: isLoading, refetch } = useGetDriversQuery();

  const selectors = entitySelector<DriverDto>(data);

  return useMemo(() => {
    return {
      data,
      error,
      isLoading,
      refetch,
      ...selectors,
    };
  }, [data, error, isLoading, refetch, selectors]);
};
