import { Grid, ImageList, ImageListItem, Typography } from "@material-ui/core";
import ServiceReportForm from "components/Form/ServiceReport";
import ServiceReportPerDateForm from "components/Form/ServiceReportPerDate";
import UpcomingReport from "components/Form/UpcomingReport";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import AssignedDriverReportForm from "../../Form/AssignedDriverReport";
import ServiceWithoutDriverForm from "../../Form/ServiceWithoutDriverReport";
import DisplayCard from "../Card";

const reports = [
  { title: "Service Report", component: ServiceReportForm },
  { title: "Service Without Driver Report", component: ServiceWithoutDriverForm },
  { title: "Service By Date Report", component: ServiceReportPerDateForm },
  { title: "Last User By Date Report", component: AssignedDriverReportForm },
  { title: "", component: UpcomingReport },
];

export const DisplayReports: FC<{ title: string }> = () => {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant='h2' sx={{ fontWeight: 700 }}>
        {t("ADM Custom Reports")}
      </Typography>
      <ImageList
        variant='standard'
        cols={3}
        gap={30}
        rowHeight={250}
        sx={{ padding: 5, height: "80vh" }}
      >
        {
        reports.map((item, index) => (
          <ImageListItem key={item.title + index}>
            <DisplayCard title={item.title}>
              <Grid component={item.component} />
            </DisplayCard>
          </ImageListItem>
        ))
        }
      </ImageList>
    </>
  );
};
