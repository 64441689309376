import { EntityState } from "@reduxjs/toolkit";
import { selectMapper } from "utility/selectMapper";

export const entitySelector = <Entity>(
  { entities, ids }: EntityState<Entity> = { entities: {}, ids: [] }
) => ({
  lookup: (term: keyof Entity) =>
    ids.reduce<Record<string, unknown>>((acc, id) => {
      const entity = entities[id];
      if (entity) {
        acc[id] = entity[term];
      }
      return acc;
    }, {}),
  selectOptions: selectMapper(entities, {
    label: "type",
    value: "id",
  }),
  list: ids.map((id) => entities[id]),
  ids: ids,
  table: ids.map((id) => entities[id]),
});
