import { EntityState } from "@reduxjs/toolkit";
import { api } from "app";
import { ServiceDto } from "./dto/service.dto";

const URL = "vehicle-services";

const vehicleServiceServices = api.injectEndpoints({
    endpoints: (build) => ({
        getServices: build.query<EntityState<ServiceDto>, void>({
            query: () => ({ url: `${URL}`, method: "GET" }),
            providesTags: [{ type: "Services" }],
        }),
        getServiceById: build.query<ServiceDto, number>({
            query: (id) => ({ url: `${URL}/${id}`, method: "GET" }),
            providesTags: (_result, _error, id) => ([{ type: "Services", id: id }]),
        }),
        editService: build.mutation({
            query: ({ id, ...data }: ServiceDto) => ({ url: `${URL}/${id}`, method: "PATCH", body: data }),
            invalidatesTags: [{ type: "Services" }],
        }),
    }),
    overrideExisting: false,
});

export default vehicleServiceServices;

export const {
    useGetServicesQuery,
    useEditServiceMutation,
    useGetServiceByIdQuery
} = vehicleServiceServices;

export type ServiceActionTypes = "createService" | "editService";
