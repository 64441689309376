import { TextField, TextFieldProps } from "@material-ui/core";
import { InputProps } from "components/Input/types";
import _ from "lodash";
import { FC } from "react";
import { Controller } from "react-hook-form";

export interface FieldTextProps extends InputProps {
    type?: string,
    autoComplete?: string
    inputProps?: TextFieldProps
}

const FieldText: FC<FieldTextProps> = ({
    label,
    name,
    disabled,
    placeholder,
    rules,
    type,
    autoComplete,
    defaultValue,
    control,
    inputProps
}) => {

    const options: TextFieldProps = {
        label: label || _.startCase(_.toLower(name)),
        disabled: disabled,
        placeholder: placeholder,
        variant: "standard",
        color: "primary",
        type: type,
        autoComplete: autoComplete,
        fullWidth: true,
        ...inputProps
    }

    return (
        <Controller
            control={control}
            name={name}
            render={({
                field,
                fieldState
            }) => {
                return (
                    <TextField
                        {...field}
                        {...options}
                        error={fieldState.invalid}
                        helperText={
                            fieldState.invalid
                            &&
                            `${fieldState.error?.message}`
                        }
                    />
                );
            }}
            defaultValue={defaultValue || ""}
            rules={rules}
        />
    );
};

export default FieldText