import { Card, CardMedia, Grid, Stack } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import logo from "resources/img/ats_logo_4k.png";
import Components from "./Components";

export const NavigationPanel = () => {
  return (
    <Stack
      component={Card}
      borderRadius={8}
      elevation={0}
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[50]
            : theme.palette.grey[100],
        width: 300,
        height: "100%",
      }}
    >
      <Grid
        component={CardMedia}
        image={logo}
        title='Paella dish'
        sx={{
          height: 0,
          width: 300,
          paddingTop: "56.25%",
        }}
      />
      <Grid
        component={CardContent}
        sx={{ width: "100%", overflow: "auto", flexGrow: 1 }}
      >
        <Components />
      </Grid>
    </Stack>
  );
};
