import { Button, Stack } from "@material-ui/core"
import FieldDate from "components/Input/Date"
import { usePage } from "context/usePage"
import { useWialon } from "context/useWialon"
import { endOfToday, getUnixTime } from "date-fns"
import { useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"

const ServiceReportForm = () => {
    const { t } = useTranslation();
    const methods = useForm({
        defaultValues: {
            toDate: new Date()
        }
    })
    const { wialon } = useWialon()
    const { setLoading, loading } = usePage()
    const [mounted, setMounted] = useState(false)

    const executeReport = async ({ toDate }: { toDate: Date }) => {
        if (toDate) {
            console.log(toDate)
            Promise.resolve(setLoading(true))
                .then(async () => await wialon?.getService(getUnixTime(toDate)))
                .finally(() => setLoading(false))
        } else {
            toast.error("Error Invalid Input Detected")
        }
    }

    useEffect(() => {
        if (!mounted) {
            methods.setValue("toDate", endOfToday());
            setMounted(true)
        }
    }, [mounted])

    return <FormProvider {...methods}>
        <Stack spacing={1}>
            <FieldDate name="toDate" rules={{ required: String(t("required_field")), }} />
            <Button variant="contained" onClick={methods.handleSubmit(executeReport)} disabled={loading}>{t("generate")}</Button>
        </Stack>
    </FormProvider>
}

export default ServiceReportForm