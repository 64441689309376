import { TextField } from "@material-ui/core";
import { DatePicker } from "@material-ui/lab";
import { InputProps } from "components/Input/types";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";

export interface FieldDateProps extends InputProps {
  // minDate?: ParsableDate;
  // maxDate?: ParsableDate;
  disablePast?: boolean;
  disableFuture?: boolean;
  inputFormat?: string;
}

const FieldDate: FC<FieldDateProps> = ({
  name,
  disabled,
  placeholder,
  rules,
  control,
  inputFormat,
}) => {
  const { control: controlContext } = useFormContext();
  return (
    <Controller
      control={control || controlContext}
      name={name}
      render={({ field, fieldState: { invalid, isTouched, error } }) => {
        return (
          <DatePicker
            {...field}
            inputFormat={inputFormat || "yyyy-MM-dd"}
            disabled={disabled}
            renderInput={(params) => (
              <TextField
                {...params}
                value={field.value}
                placeholder={placeholder || "yyyy-MM-dd hh:mm"}
                error={invalid && isTouched}
                variant='outlined'
                helperText={invalid && `${error?.message}`}
              />
            )}
          />
        );
      }}
      rules={rules}
    />
  );
};

export default FieldDate;
