import { ListItemButton } from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { SxProps, Theme } from "@material-ui/system";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

export interface LinkedItemProps {
  title: string;
  path?: string;
  icon: JSX.Element;
  isAllowed: boolean;
  isNested?: boolean;
  route?: LinkedItemProps[];
  sx?: SxProps<Theme>;
}

export const LinkedItem = ({ title, path, icon, sx }: LinkedItemProps) => {
  const { t } = useTranslation();
  const search = useLocation().search;
  const queryParams = new URLSearchParams(search).toString() || "";
  const to = (path || "") + "?" + queryParams;
  return (
    <ListItemButton component={Link} to={to} sx={sx}>
      <ListItemIcon sx={{ fontSize: 20 }}>{icon}</ListItemIcon>
      <ListItemText
        primary={t(title)}
        primaryTypographyProps={{
          fontSize: 20,
          fontWeight: "medium",
          letterSpacing: 0,
        }}
      />
    </ListItemButton>
  );
};
