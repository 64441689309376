import { Card, CardContent, Typography } from "@material-ui/core";
import { FC } from "react";
import { useTranslation } from "react-i18next";

const DisplayCard: FC<{ title: string }> = ({ children, title }) => {
    const { t } = useTranslation();
    return <Card sx={{ flexGrow: 1, overflow: "auto" }}>
        <CardContent>
            <Typography gutterBottom variant="h4" component="div" sx={{ marginBottom: 5 }}>
                {t(title)}
            </Typography>
            {children}
        </CardContent>
    </Card>
};

export default DisplayCard;