import { Button, Grid, Stack, Typography } from "@material-ui/core";
import FieldDate from "components/Input/Date";
import { usePage } from "context/usePage";
import { useWialon } from "context/useWialon";
import { endOfToday, getUnixTime, startOfDay, startOfToday } from "date-fns";
import { endOfDay } from "date-fns/esm";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const ServiceReportPerDateForm = () => {
  const { t } = useTranslation();
  const methods = useForm({
    defaultValues: {
      fromDate: new Date(),
      toDate: new Date()
    }
  });
  const { wialon } = useWialon();
  const { setLoading, loading } = usePage();
  const [mounted, setMounted] = useState(false);

  const executeReport = async ({
    fromDate,
    toDate,
  }: {
    fromDate: Date;
    toDate: Date;
  }) => {
    if (fromDate && toDate) {
      Promise.resolve(setLoading(true))
        .then(
          async () =>
            await wialon?.getServiceHistory({
              fromDate: getUnixTime(startOfDay(fromDate)),
              toDate: getUnixTime(endOfDay(toDate)),
            })
        )
        .finally(() => setLoading(false));
    } else {
      toast.error("Error Invalid Input Detected");
    }
  };

  useEffect(() => {
    if (!mounted) {
      methods.setValue("fromDate", startOfToday());
      methods.setValue("toDate", endOfToday());
      setMounted(true);
    }
  }, [mounted]);

  return (
    <FormProvider {...methods}>
      <Stack spacing={1}>
        <Grid
          container
          flexDirection='row'
          flexWrap='nowrap'
          columnGap={1}
          alignItems='center'
        >
          <Grid
            item
            component={FieldDate}
            name='fromDate'
            rules={{ required: String(t("required_field")) }}
          />
          <Typography>To</Typography>
          <Grid
            item
            component={FieldDate}
            name='toDate'
            rules={{ required: String(t("required_field")) }}
          />
        </Grid>
        <Button
          variant='contained'
          onClick={methods.handleSubmit(executeReport)}
          disabled={loading}
        >
          {t("generate")}
        </Button>
      </Stack>
    </FormProvider>
  );
};

export default ServiceReportPerDateForm;
