import { mapValues } from "lodash";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";

export const exportToExcel = ({ title, sheets }: ExportExcelProps) => {
  if (!sheets || sheets?.length == 0) {
    return toast.info("No Service found on the given dates");
  }

  const workbook = XLSX.utils.book_new();
  workbook.Props = {
    Title: title,
    Subject: title,
    Author: "Advance Telematics Solutions LLC",
    CreatedDate: new Date(),
  };

  sheets.forEach((sheet) => {
    // const baseSheet = XLSX.utils.json_to_sheet(
    //     [
    //         {
    //             header: "ADM - Service Report",
    //         },
    //         {
    //             header: new Date(),
    //         },
    //         {
    //             header: "Abu Dhabi Municipality",
    //         },
    //     ],
    //     { skipHeader: true, sheetStubs: true, cellStyles: true }
    // );
    // const excelSheet = XLSX.utils.sheet_add_json(baseSheet, sheet.data, {
    //     origin: "A4",
    //     cellStyles: true
    // });
    const originalSheet = XLSX.utils.json_to_sheet(sheet.data);
    autofitColumns(sheet.data, originalSheet);
    XLSX.utils.book_append_sheet(workbook, originalSheet, sheet.title);
  });
  const fileName = title
    ? title.includes(".")
      ? title
      : title + ".xlsx"
    : "ADM-آخر مستخدم.xlsx";
  return XLSX.writeFile(workbook, fileName);
};

export interface ExportExcelProps {
  title: string;
  sheets: { title: string; data: Record<string, string>[] }[];
}

export const autofitColumns = (
  json: Record<string, string>[],
  worksheet: XLSX.WorkSheet
) => {
  const objectMaxLength: number[] = [];
  if (json[0]) {
    Object.keys(json[0]).forEach((k, i) => {
      objectMaxLength[i] = k.length;
    });
  }
  // console.log(Object.keys(json[0]))

  json.forEach((jsonData) => {
    Object.values(jsonData).forEach((v, i) => {
      const valueLength = v && String(v).length;
      if (!objectMaxLength[i]) {
        objectMaxLength[i] = 1;
      }
      if (valueLength && objectMaxLength[i] <= valueLength) {
        objectMaxLength[i] = valueLength;
      }
    });
  });
  objectMaxLength[0] = objectMaxLength[0] + 5;
  const wscols = objectMaxLength.map((w: number) => ({ wch: w }));
  worksheet["!cols"] = wscols;

  const defaultCellStyle = {
    font: { name: "Verdana", sz: 11, color: "FF00FF88" },
    fill: { fgColor: { rgb: "FFFFAA00" } },
  };

  worksheet = mapValues(worksheet, (item) => {
    if (Object.keys(item).every((term) => ["t", "v"].includes(term))) {
      return {
        ...item,
        s: defaultCellStyle,
      };
    }
    return item;
  });
  console.log(worksheet);
};
