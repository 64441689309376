import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationAr from "./resources/locales/ar/translation.json";
import translationEn from "./resources/locales/en/translation.json";

export const defaultNS = 'en'

export const resources = {
    en: {
        translation: translationEn
    },
    ar: {
        translation: translationAr
    },
}

// .use(Backend)
// .use(LanguageDetector)
i18n.use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'en',
    });

export default i18n;