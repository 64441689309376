import MaterialTable, {
  MaterialTableProps,
  Options,
} from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { omit } from "lodash";
import { TABLE_ICONS } from "./Icons";

// eslint-disable-next-line @typescript-eslint/ban-types
export interface TableProps<RowData extends object>
  extends MaterialTableProps<RowData> {
  optionProps?: Options<RowData>;
  touchMe?: string;
  getData?: () => void;
  reportTitle?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Table = <RowData extends Record<string, any>>(
  props: TableProps<RowData>
) => {
  return (
    <MaterialTable
      icons={TABLE_ICONS}
      options={{
        filtering: true,
        pageSize: 10,
        pageSizeOptions: [10, 20, 50, 100],
        columnsButton: true,
        exportAllData: true,
        exportMenu: [
          {
            label: "Export PDF",
            exportFunc: (cols, datas) =>
              ExportPdf(cols, datas, props.reportTitle || "Custom_Report"),
          },
          {
            label: "Export Excel",
            exportFunc: (cols, datas) =>
              ExportCsv(cols, datas, props.reportTitle || "Custom_Report"),
          },
        ],
        padding: "dense",
        ...props.optionProps,
      }}
      {...omit(props, "classes")}
    />
  );
};

export default Table;
